import cn from 'classnames';
import styles from './Tag.module.scss';
import { MouseEvent } from 'react';
import { useRouter } from 'next/router';

type TagProps = {
  text: string;
  className?: string;
  link?: string;
};

export function Tag({ text, className, link }: TagProps) {
  const { push } = useRouter();

  const tagClassName = cn(
    styles.tag,
    {
      [styles.pointer]: link,
    },
    className,
    'custom-tag',
  );

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (link) {
      push(link);
    }
  };

  return (
    <div className={tagClassName} onClick={handleClick}>
      {text}
    </div>
  );
}
